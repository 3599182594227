import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import PrivateRoutes from "./pages/Routes/PrivateRoutes";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import ProductsTable from "./pages/ProductsTable";
import Orders from "./pages/Orders";
import Invoices from "./pages/Invoices";
import Statements from "./pages/Statements";
import Information from "./pages/Information";
import Contact from "./pages/Contact";
import Profile from "./pages/Profile";
import Login from "./pages/Login";
import Vendors from "./pages/Vendors";
import Reports from "./pages/Reports";
import Backorders from "./pages/Backorders";
import ReturnPolicy from "./pages/ReturnPolicy";
import Wishlist from "./pages/Wishlist";
import Layout from "./Layout";
import { Providers } from "./store/provider";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import toast from "react-hot-toast";
import ReturnProduct from "./pages/ReturnProduct";

//import router from "./Routes/routes";

function App() {
  const [isVendorName, setIsVendorName] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const token =
    localStorage.getItem("kedTokAuth") || sessionStorage.getItem("kedTokAuth");
  const [isAuthenticated, setIsAuthenticated] = useState(token ? true : false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasClearedCache, setHasClearedCache] = useState(false);

  useEffect(() => {
    if (!hasClearedCache) {
      // Function to clear cookies
      const clearCookies = () => {
        const cookies = document.cookie ? document.cookie?.split(";") : [];
        for (let cookie of cookies) {
          const eqPos = cookie.indexOf("=");
          const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
          document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;`;
        }
      };

      // Clear localStorage and sessionStorage
      // localStorage.clear();
      // sessionStorage.clear();

      // Unregister service workers to clear SW cache
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then(function (registrations) {
          for (let registration of registrations) {
            registration.unregister();
          }
        });
      }

      // Clear cookies
      clearCookies();

      // Set state to indicate cache has been cleared
      setHasClearedCache(true);
    }
  }, [hasClearedCache]);

  // Logout
  function logout() {
    localStorage.removeItem("kedTokAuth");
    sessionStorage.removeItem("kedTokAuth");
    setIsAuthenticated(false);
  }

  function checkSessionTimeout(userDetails) {
    if (!userDetails?.loginTime) {
      console.error("Invalid userDetails or loginTime.");
      return;
    }

    const currentDate = new Date();
    const loginTime = new Date(userDetails.loginTime);

    if (isNaN(loginTime.getTime())) {
      console.error("Invalid loginTime format.");
      return;
    }

    const diffInMs = currentDate - loginTime;
    const diffInHours = diffInMs / (1000 * 60 * 60);

    // Example: If session timeout is set to 2 hours
    const sessionTimeoutHours = 8;

    if (diffInHours >= sessionTimeoutHours && window.innerWidth > 1024) {
      console.log("Session has timed out.");
      // Call logout function or handle session timeout logic
      toast.error("Session has been expired! Please login again");
      logout();
    } else {
      console.log("Session is still active.");
    }
  }

  useEffect(() => {
    const user = localStorage.getItem("userDetails");
    const userDetails = user ? JSON.parse(user) : user;
    if (userDetails) {
      checkSessionTimeout(userDetails);
    }
  }, []);

  useEffect(() => {
    const validateToken = async () => {
      if (!token) {
        setIsAuthenticated(false);
        return;
      }
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/check-token?token=${token}`
        );

        if (response.status === 200) {
          // Token is valid
          if (response.data.role == "vendor") {
            setIsVendorName(response.data.userId);
            setIsAuthenticated(true);
          }
          if (response.data.role == "Admin") {
            setIsAdmin(response.data.role);
            setIsAuthenticated(true);
          }
        } else {
          // Other error occurred
          throw new Error("Failed to authenticate token.");
        }
      } catch (error) {
        setIsAuthenticated(false);
        localStorage.removeItem("kedTokAuth");
        sessionStorage.removeItem("kedTokAuth");
        console.error(error);
        // Handle the error, e.g., show an error message or redirect to an error page
      }
    };

    validateToken();
  }, [token]);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        {!isAuthenticated ? (
          <>
            <Route
              path="/"
              element={
                <Login
                  setIsAuthenticated={setIsAuthenticated}
                  setIsVendorName={setIsVendorName}
                  setIsAdmin={setIsAdmin}
                  setIsLoading={setIsLoading}
                />
              }
            />
            <Route path="/contact" element={<Contact />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="*" element={<Navigate to="/" />} />
          </>
        ) : (
          <Route
            element={
              <Navbar
                isVendorName={isVendorName}
                isAdmin={isAdmin}
                setIsAuthenticated={setIsAuthenticated}
              />
            }
          >
            {isAdmin ? (
              <>
                <Route
                  path="/app"
                  element={<Home isVendorName={isVendorName} />}
                />
                <Route
                  path="/app/reports"
                  element={<Reports isAdmin={isAdmin} />}
                />
              </>
            ) : (
              <>
                <Route
                  path="/app"
                  element={<Home isVendorName={isVendorName} />}
                />
                <Route
                  path="/app/catalogue"
                  element={<ProductsTable isVendorName={isVendorName} />}
                />
                <Route
                  path="/app/orders"
                  element={<Orders isVendorName={isVendorName} />}
                />
                <Route path="/app/invoices" element={<Invoices />} />
                <Route
                  path="/app/customer-statements"
                  element={<Statements />}
                />
                <Route
                  path="/app/general-information"
                  element={<Information />}
                />
                <Route path="/app/profile" element={<Profile />} />
                <Route path="/app/vendors" element={<Vendors />} />
                <Route path="/app/reports" element={<Reports />} />
                <Route path="/app/return-product" element={<ReturnProduct />} />
                <Route
                  path="/app/backorders"
                  element={<Backorders isVendorName={isVendorName} />}
                />
                <Route path="/app/return-policy" element={<ReturnPolicy />} />
                <Route path="/app/profile" element={<Profile />} />
                <Route path="/app/wishlist" element={<Wishlist />} />
                <Route path="/app/contact" element={<Contact />} />
                <Route path="/app/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="*" element={<Navigate to="/app" />} />
              </>
            )}
          </Route>
        )}
      </>
    )
  );

  return (
    <div className="App">
      {isLoading ? (
        <p style={{ textAlign: "center" }}>Loading...</p>
      ) : (
        <Providers>
          <Layout>
            <RouterProvider router={router} />
          </Layout>
        </Providers>
      )}
    </div>
  );
}
export default App;
//export default withAuth(App);
