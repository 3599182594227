import { globalAction } from "../action";
import {
  RESET_CART_DETAILS,
  SET_CART_DETAILS,
  SET_CART_DISCOUNT,
  SET_MIX_MATCH_DISCOUNT,
  SET_EXTRA_ITEMS,
  SET_EXTRA_DISCOUNT,
} from "../actionTypes";

export const setCartDetails = (data) => {
  return (dispatch) => {
    dispatch(globalAction(SET_CART_DETAILS, data));
  };
};

export const setCartDiscount = (data) => {
  return (dispatch) => {
    dispatch(globalAction(SET_CART_DISCOUNT, data));
  };
};

export const setMixMatchDiscount = (data) => {
  return (dispatch) => {
    dispatch(globalAction(SET_MIX_MATCH_DISCOUNT, data));
  };
};

export const resetCartDetails = (data) => {
  return (dispatch) => {
    dispatch(globalAction(RESET_CART_DETAILS, data));
  };
};

export const setItemExtraFree = (data) => {
  return (dispatch) => {
    dispatch(globalAction(SET_EXTRA_ITEMS, data));
  };
};

export const setItemExtraDiscount = (data) => {
  return (dispatch) => {
    dispatch(globalAction(SET_EXTRA_DISCOUNT, data));
  };
};

export const initialState = {
  cartList: [],
  totalCartItem: 0,
  mixMatchItems: {},
  freeCartItem: [],
  totalFreeCartItem: 0,
  freeItemQuantity: {},
  kediCartDiscountLabel: {},
  cartDiscount: 0.0,
  mixMatchDiscount: {},
  totalMixMatchDiscount: 0,
  cartGrossTotal: 0.0,
  cartNetTotal: 0.0,
  totalFreeItemsAmount: 0.0,
  discountAmountOnItems: 0.0,
  currentSessionCartData: [],
  itemsExtraFree: {},
  itemsExtraDiscount: {},
  totalExtraFreeItemDiscount: 0,
  totalExtraFreeItem: 0,
  totalExtraDiscount: 0,
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CART_DETAILS:
      let cartDiscount = state.cartDiscount;

      // CALCULATE CART GROSS TOTAL AND TOTAL ITEMS
      let cartGrossTotal = state?.cartGrossTotal;
      let totalCartItem = state?.totalCartItem;
      if (action.payload?.cartList) {
        // GROSS TOTAL
        cartGrossTotal =
          action.payload.cartList.length > 0
            ? action.payload.cartList
                .reduce(
                  (total, item) =>
                    total +
                    Number(item?.quantity || 1) * Number(item?.WSPLPRICE),
                  0
                )
                .toFixed(2)
            : 0.0;

        // TOTAL ITEMS
        totalCartItem =
          action.payload.cartList.length > 0
            ? action.payload.cartList.reduce(
                (total, item) => total + Number(item?.quantity || 1),
                0
              )
            : 0;
      }

      let totalFreeItemsAmount = state.totalFreeItemsAmount;
      let totalFreeCartItem = state.totalFreeCartItem;
      // CONVERT OBJECT INTO ARRAY
      const convertObjectToArray = (obj) => {
        return Object.keys(obj).map((key) => {
          return {
            PARTNAME: key,
            quantity: obj[key].quantity,
            WSPLPRICE: obj[key].WSPLPRICE,
            OFFERQTY: obj[key].OFFERQTY,
            selectedDiscount: obj[key].selectedDiscount,
            totalDiscount: obj[key].totalDiscount,
          };
        });
      };

      if (action.payload?.freeItemQuantity) {
        const freeItemArray = convertObjectToArray(
          action.payload?.freeItemQuantity
        );
        // CALCULATE TOTAL DISCOUNT ON ITEM WITH CONDITION ITEM FREE OR PERCENT DISCOUNT
        totalFreeItemsAmount =
          freeItemArray.length > 0
            ? freeItemArray
                .reduce((total, item) => {
                  const quantity = Number(item?.quantity) || 0;
                  const wsplPrice = Number(item?.WSPLPRICE) || 0;
                  const offerQty = Number(item?.OFFERQTY) || 0;
                  const discount =
                    Number(item?.selectedDiscount?.DISCOUNT) || 0;

                  const itemTotal =
                    quantity !== 0
                      ? quantity * wsplPrice
                      : // : (offerQty * wsplPrice * discount) / 100;
                        Number(item?.totalDiscount);

                  return total + itemTotal;
                }, 0)
                .toFixed(2)
            : 0.0;

        // CALCULATE TOTAL FREE ITEMS
        totalFreeCartItem =
          freeItemArray.length > 0
            ? freeItemArray.reduce(
                (total, item) => total + Number(item?.quantity),
                0
              )
            : 0;
      }

      // FINAL CART DISCOUNT
      cartDiscount = totalFreeItemsAmount;

      return {
        ...state,
        ...action.payload,
        cartGrossTotal,
        cartDiscount,
        cartNetTotal:
          Number(cartGrossTotal) -
          Number(cartDiscount) -
          Number(state.totalMixMatchDiscount).toFixed(2),
        totalCartItem,
        totalFreeItemsAmount,
        totalFreeCartItem,
      };

    case SET_CART_DISCOUNT:
      return {
        ...state,
        cartDiscount: action.payload.cartDiscount,
      };

    case SET_MIX_MATCH_DISCOUNT:
      return {
        ...state,
        mixMatchDiscount: action.payload.mixMatchDiscount,
        totalMixMatchDiscount: action.payload.totalMixMatchDiscount,
      };

    case SET_EXTRA_ITEMS:
      let totalExtraFreeItemDiscount = 0;
      let totalExtraFreeItem = 0;
      for (const key in action.payload.itemsExtraFree) {
        if (action.payload.itemsExtraFree.hasOwnProperty(key)) {
          const item = action.payload.itemsExtraFree[key];
          const quantity = Number(item.quantity);
          const price = Number(item.WSPLPRICE);
          totalExtraFreeItemDiscount += quantity * price;
          totalExtraFreeItem += quantity;
        }
      }
      return {
        ...state,
        itemsExtraFree: action.payload.itemsExtraFree,
        totalExtraFreeItemDiscount,
        totalExtraFreeItem,
      };

    case SET_EXTRA_DISCOUNT:
      let totalExtraDiscount = 0;
      for (const key in action.payload.itemsExtraDiscount) {
        if (action.payload.itemsExtraDiscount.hasOwnProperty(key)) {
          const item = action.payload.itemsExtraDiscount[key];
          const quantity = Number(item.quantity);
          const price = Number(item.WSPLPRICE);
          const percent = Number(item.percent);
          const totalPrice = quantity * price;
          totalExtraDiscount += totalPrice * (percent / 100);
        }
      }
      return {
        ...state,
        itemsExtraDiscount: action.payload.itemsExtraDiscount,
        totalExtraDiscount,
      };

    case RESET_CART_DETAILS:
      return { ...state, ...initialState };

    default:
      return state;
  }
};

export default cartReducer;
