import { toast } from "react-hot-toast";
import {
  setCartDetails,
  setMixMatchDiscount,
} from "../../../store/reducer/cart";
import { getMixMatchDiscount } from "./getMixMatchDiscount";

const handleAddToCartFun = async (payload) => {
  const {
    row,
    setShowCart,
    cartList,
    dispatch,
    singleUnit,
    selectedDiscount,
    freeItemQuantity,
    mixMatchItems,
    selectedMixMatch,
    cartDiscount,
    mixMatchDiscount,
    singleUnitWithOffer,
  } = payload;

  // STOCK IS 0 HANDLE ERROR AND RETURN
  // if (stock == 0) {
  //   toast.error("Sorry! the selected product dosn't have available stock!");
  //   return;
  // }

  // if (cartList && cartList?.length === 40) {
  //   toast.error(
  //     "You have reached the limit of ordered items. Please send order and continue with new one."
  //   );
  //   return;
  // }

  // IF PRODUCT ALREADY IN CART HANDLE ERROR AND RETURN
  const found = cartList.find((element) => element.PARTNAME === row?.PARTNAME);
  if (found && !selectedMixMatch) {
    toast.error("Product is already in the cart!");
    return;
  }

  // ADD TO CART WHEN WILL BE SIMPLE ADD TO CART WITH QUANTITY 1 WITHOUT ANY CHANGES
  if (singleUnit) {
    if (!row?.quantity) {
      row.quantity = 1;
    }
    dispatch(setCartDetails({ cartList: [...cartList, row] }));
    toast.success("Product added in the cart!");
  }

  // ADD TO CART WITH OFFERED ADDITIONAL ITEM
  if (
    selectedDiscount?.DEXT_OFFERCODE &&
    Number(selectedDiscount.DEXT_OFFERCODE) !== 4 &&
    Number(selectedDiscount?.FREEQTY) !== 0
  ) {
    if (!singleUnitWithOffer) {
      row.quantity = Number(selectedDiscount?.OFFERQTY);
    }
    const wsplPrice = Number(row?.WSPLPRICE || 0);
    const offerQty = Number(selectedDiscount?.OFFERQTY || 0);
    const discount = Number(selectedDiscount?.DISCOUNT || 0);
    dispatch(
      setCartDetails({
        cartList: [...cartList, row],
        freeItemQuantity: {
          ...freeItemQuantity,
          [row.PARTNAME]: {
            quantity: Number(selectedDiscount?.FREEQTY || 0),
            WSPLPRICE: row?.WSPLPRICE,
            OFFERQTY: Number(selectedDiscount?.OFFERQTY),
            selectedDiscount: selectedDiscount, // In Precentage
            totalDiscount: (offerQty * wsplPrice * discount) / 100,
          },
        },
      })
    );
    toast.success("Product added in the cart!");
  }

  // ADD TO CART WITH OFFERED IN DISCOUNT
  if (Number(selectedDiscount?.FREEQTY) === 0) {
    if (!singleUnitWithOffer) {
      row.quantity = Number(selectedDiscount?.OFFERQTY);
    }
    const wsplPrice = Number(row?.WSPLPRICE || 0);
    const offerQty = Number(selectedDiscount?.OFFERQTY || 0);
    const discount = Number(selectedDiscount?.DISCOUNT || 0);
    dispatch(
      setCartDetails({
        cartList: [...cartList, row],
        freeItemQuantity: {
          ...freeItemQuantity,
          [row.PARTNAME]: {
            quantity: 0,
            WSPLPRICE: row?.WSPLPRICE,
            OFFERQTY: Number(selectedDiscount?.OFFERQTY),
            selectedDiscount: selectedDiscount, // In Precentage
            totalDiscount: Number((offerQty * wsplPrice * discount) / 100) || 0,
          },
        },
      })
    );
    toast.success("Product added in the cart!");
  }

  // ADD TO CART MIXMATCH ITEMS
  if (selectedMixMatch) {
    const found = cartList.find(
      (element) => element.PARTNAME === selectedMixMatch?.PARTNAME
    );
    if (found) {
      toast.error("Product is already in the cart!");
      return;
    }

    const allMixMatchItems = [...mixMatchItems, selectedMixMatch];
    dispatch(
      setCartDetails({
        cartList: [...cartList, selectedMixMatch],
        mixMatchItems: {
          [row?.PARTNAME]: allMixMatchItems,
        },
      })
    );
    const parentPartname = selectedMixMatch?.selectedDiscount?.PARTNAME;
    if (freeItemQuantity[parentPartname]) return;

    const allDiscount = selectedMixMatch?.discounts;
    const PARTNAME = row?.PARTNAME;
    const currentMixMatchDiscount = mixMatchDiscount[PARTNAME];

    const { discountApplicableonMixMatch } = await getMixMatchDiscount(
      allDiscount,
      allMixMatchItems,
      currentMixMatchDiscount
    );

    const allPartMixMatchDiscount = {
      ...mixMatchDiscount,
      [PARTNAME]: discountApplicableonMixMatch,
    };
    const totalValue = Object.values(allPartMixMatchDiscount).reduce(
      (total, value) => total + value,
      0
    );

    dispatch(
      setMixMatchDiscount({
        mixMatchDiscount: allPartMixMatchDiscount,
        totalMixMatchDiscount: totalValue,
      })
    );

    toast.success("Product added in the cart!");
  }

  // SHOW CART MODAL
  // if (window.innerWidth > 1024) {
  //   setShowCart(true);
  // }
};

export default handleAddToCartFun;
