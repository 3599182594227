import BackordersTable from "../components/Catalogue/BackordersTable";

function BackOrders({isVendorName}) {
    return (
        <div>
            <h1 className="text-2xl py-4">Back orders</h1>
            <BackordersTable isVendorName={isVendorName} />
        </div>
    );
}

export default BackOrders;