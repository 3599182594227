import { useNavigate } from "react-router-dom";
import MaterialTable from "../components/Catalogue/MaterialTable";
import forwardLogo from "../assets/forward-logo.png";
import backLogo from "../assets/back-logo.png";
import { useState } from "react";

function ProductsTable({ isVendorName }) {
  const navigate = useNavigate();
  return (
    <div>
      <div className="flex gap-1 items-center justify-between py-3 lg:py-10 px-2 bg-white lg:static sticky top-0 left-0 z-[100] lg:shadow-none shadow-md">
        {/* <img
          src={backLogo}
          width={25}
          height={10}
          onClick={() => navigate(-1)}
          className="lg:hidden"
        /> */}

        <div
          className="flex items-center cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <img
            src={backLogo}
            width={25}
            height={10}
            onClick={() => navigate(-1)}
            className="lg:hidden"
          />
          <h1 className="text-xl md:text-2xl">Κατάλογος</h1>
        </div>
        {/* <button className="basket-mobile md:hidden" onClick={handleCartClick}>
          <span className="icon-mobile">
            {totalItems ? JSON.parse(totalItems).length : 0}
          </span>
        </button> */}
      </div>
      <MaterialTable isVendorName={isVendorName} />
    </div>
  );
}

export default ProductsTable;
