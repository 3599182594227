import {
  Autocomplete,
  Button,
  Checkbox,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { fetchPartsApi } from "../components/Catalogue/Api/partsApi";
import { ReturnProductPdf } from "../components/ReturnProductPdf";
import { pdf } from "@react-pdf/renderer";

const ReturnProduct = () => {
  const [allProducts, setAllProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [allReturnProducts, setAllReturnProducts] = useState([]);
  const [boxNumber, setBoxNumber] = useState("");
  let userFullId = localStorage.getItem("userId");

  const searchProducts = () => {
    fetchPartsApi(`?filters=[{"id":"PARTNAME","value":${searchValue}}]`).then(
      (response) => {
        setAllProducts(response?.data);
      }
    );
    // axios.get(`https://portal.kedifap.com/parts/?filters=[{"id":"PARTNAME","value":${searchValue}}]`).then((res) => {
    //   setAllProducts(res?.data?.data);
    // });
  };

  useEffect(() => {
    if (searchValue.length > 3) searchProducts();
  }, [searchValue]);

  // useEffect(() => {
  //   searchProducts();
  // }, []);

  console.log(allReturnProducts, "allReturnProducts");

  const handleSubmitForm = async () => {
    console.log("dddsd");
    const blob = await pdf(
      <ReturnProductPdf
        allReturnProducts={allReturnProducts}
        otherInfo={{
          boxNumber,
          serialNo: "0001",
          customer: { userFullId: userFullId, name: "Test Customer" },
        }}
      />
    ).toBlob();
    const blobUrl = URL.createObjectURL(blob);
    console.log(blobUrl, "blobUrl");
    window.open(blobUrl, "_blank");
  };

  return (
    <div>
      <h2 className="text-2xl uppercase font-bold text-center">Return Form</h2>
      <div className="overflow-x-auto my-3">
        <table className="min-w-full bg-white border border-gray-300">
          <tbody>
            {/* First Row */}
            <tr className="border-b border-gray-300">
              <td className="p-3 border-r border-gray-300 font-semibold">
                CODE
              </td>
              <td className="p-3 border-r border-gray-300 font-semibold">
                Description
              </td>
              <td className="p-3 border-r border-gray-300 font-semibold">
                Return Policy
              </td>
              <td className="p-3 border-r border-gray-300 font-semibold">
                Exp. Date
              </td>
              <td className="p-3 border-r border-gray-300 font-semibold">
                Lot No.
              </td>
              <td className="p-3 border-r border-gray-300 font-semibold">
                QTY
              </td>
              <td className="p-3 font-semibold">Special Agreement</td>
            </tr>

            {/* Second Row */}
            {allReturnProducts?.length > 0 &&
              allReturnProducts.map((item, index) => (
                <tr key={index} className="border-b border-gray-300">
                  <td className="p-3 border-r border-gray-300">
                    {item?.PARTNAME}
                  </td>
                  <td className="p-3 border-r border-gray-300">
                    {item?.PARTDES}
                  </td>
                  <td className="p-3 border-r border-gray-300">
                    {item?.DEXT_CSPOLICYCODE}
                  </td>
                  <td className="p-3 border-r border-gray-300">
                    {item?.expiry}
                  </td>
                  <td className="p-3 border-r border-gray-300">
                    {item?.lotNo}
                  </td>
                  <td className="p-3 border-r border-gray-300">{item?.qty}</td>
                  <td className="p-3">
                    <Checkbox
                      onChange={(e) => {
                        setAllReturnProducts((prevProducts) => {
                          // Create a copy of the previous state
                          const updatedProducts = [...prevProducts];

                          // Update the specific item at the given index
                          updatedProducts[index] = {
                            ...updatedProducts[index],
                            agreement: e.target.checked,
                          };

                          // Return the modified array to update the state
                          return updatedProducts;
                        });
                      }}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="p-3 rounded-md flex justify-between items-center bg-slate-100">
        <Typography fontWeight={700} className="w-full">
          Add Product in Return Form:
        </Typography>
        <div className="flex justify-end items-center w-full gap-2">
          <Autocomplete
            size="small"
            disablePortal
            id="combo-box-demo"
            sx={{ width: 200 }}
            options={allProducts}
            value={selectedProducts || null}
            getOptionLabel={(option) => option?.PARTNAME || ""}
            renderInput={(params) => (
              <TextField
                sx={{ maxWidth: 200 }}
                {...params}
                label="Enter Code"
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              />
            )}
            onChange={(event, newValue) => {
              setSelectedProducts(newValue || {});
            }}
          />
          <TextField
            size="small"
            type="number"
            sx={{ maxWidth: 100 }}
            placeholder="Qty"
            value={selectedProducts?.qty || ""}
            onChange={(e) => {
              if (selectedProducts) {
                setSelectedProducts((prev) => ({
                  ...prev,
                  qty: Number(e.target.value),
                }));
              }
            }}
          />
          <TextField
            size="small"
            sx={{ maxWidth: 100 }}
            placeholder="Lot no."
            value={selectedProducts?.lotNo || ""}
            onChange={(e) => {
              if (selectedProducts) {
                setSelectedProducts((prev) => ({
                  ...prev,
                  lotNo: e.target.value,
                }));
              }
            }}
          />
          <Button
            variant="contained"
            onClick={() => {
              const isAlreadyAdded = allReturnProducts.some(
                (item) => item?.PARTNAME === selectedProducts?.PARTNAME
              );
              if (isAlreadyAdded) {
                alert("This Part is already added");
                return;
              }
              if (selectedProducts?.PARTNAME && selectedProducts?.qty) {
                setAllReturnProducts((prev) => [...prev, selectedProducts]);
                setSelectedProducts({});
              }
            }}
          >
            Add
          </Button>
        </div>
      </div>
      {allReturnProducts?.length > 0 && (
        <div className="my-3 flex flex-col gap-3 justify-start items-start">
          <TextField
            label="Enter Box Number"
            placeholder="Box number"
            value={boxNumber}
            onChange={(e) => setBoxNumber(e.target.value)}
            size="small"
          />
          <Button
            variant="contained"
            color="success"
            size="large"
            disabled={!boxNumber}
            onClick={handleSubmitForm}
          >
            Submit Form
          </Button>
        </div>
      )}
    </div>
  );
};

export default ReturnProduct;
