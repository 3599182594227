import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";

import Button from "react-bootstrap/Button";
import { FaCartArrowDown } from "react-icons/fa";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import { FetchOffer } from "./Api/fetchOffer";
import toast, { Toaster } from "react-hot-toast";

import "./DiscountModal.css";
import { useDispatch, useSelector } from "react-redux";
import {
  initialState,
  setCartDetails,
  setCartDiscount,
  setMixMatchDiscount,
} from "../../store/reducer/cart";
import { getMixMatchDiscount } from "./Material-items/getMixMatchDiscount";

function ProductDiscountModal(props) {
  const {
    show,
    handleClose,
    popupModalDiscount,
    setShowCart,
    handleAddToCart,
  } = props;

  const [currentBody, setCurrentBody] = useState(1);
  const [currentSelectedQuantity, setCurrentSelectedQuantity] = useState(1);
  const [mixMatch, setMixMatch] = useState([]);
  const [totalMixMatchAddedInCart, setTotalMixMatchAddedInCart] = useState(0);
  const modalRef = useRef();
  const dispatch = useDispatch();
  const {
    cartList,
    cartGrossTotal,
    cartDiscount,
    cartNetTotal,
    totalCartItem,
    totalFreeCartItem,
    totalMixMatchDiscount,
    freeItemQuantity,
    mixMatchItems,
    mixMatchDiscount,
  } = useSelector((state) => state.cart);
  const { rowSearch } = popupModalDiscount;
  const productOriginal = { original: rowSearch };

  const handleCloseModal = () => {
    handleClose();
    setCurrentBody(1);
  };

  useEffect(() => {
    const totalItems =
      mixMatchItems?.[rowSearch?.PARTNAME] &&
      mixMatchItems?.[rowSearch?.PARTNAME].length > 0
        ? mixMatchItems?.[rowSearch?.PARTNAME].reduce(
            (total, item) => total + Number(item?.quantity || 1),
            0
          )
        : 0;

    setTotalMixMatchAddedInCart(totalItems);
  }, [mixMatchItems]);

  const handleSwipe = async (direction, product, index, discount) => {
    if (direction === "left") {
      setCurrentBody(currentBody + 1);
    } else if (direction === "right" && currentBody > 1) {
      setCurrentBody(currentBody - 1);
    }

    let offerResults = await FetchOffer(index);

    //add the quantity balance for all same products
    const updatedOfferResults = Object.values(
      offerResults.data.reduce((result, item) => {
        const partname = item.PARTNAME;
        const tbalance = parseInt(item.TBALANCE);

        if (!result[partname]) {
          result[partname] = { ...item };
        } else {
          result[partname].TBALANCE =
            parseInt(result[partname].TBALANCE) + tbalance;
        }

        return result;
      }, {})
    );
    const updatedItemsWithDiscount = updatedOfferResults.map((item) => {
      const cartItem = cartList.filter(
        (element) => element?.PARTNAME === item?.PARTNAME
      );
      return {
        ...item,
        quantity: cartItem.length > 0 ? Number(cartItem[0]?.quantity) : 1,
        selectedDiscount: discount,
        discounts: rowSearch?.discounts,
      };
    });
    setMixMatch(updatedItemsWithDiscount);
  };

  const handleUpdateCart = async (item, currentQuantity) => {
    let product = { ...item };
    const PARTNAME = product?.PARTNAME;
    // Update Cart Item with new quantity
    product.quantity = currentQuantity;
    const updatedCartList = cartList.map((item) => {
      if (item?.PARTNAME === PARTNAME) {
        return {
          ...item,
          quantity: Number(currentQuantity),
        };
      }
      return item;
    });
    dispatch(
      setCartDetails({
        cartList: updatedCartList,
      })
    );

    const mixMatchParentPartname =
      product?.selectedDiscount?.DEXT_OFFERPARTNAME;

    let updatedMixMatch = mixMatchItems[mixMatchParentPartname];
    if (mixMatchItems[mixMatchParentPartname]) {
      updatedMixMatch = mixMatchItems[mixMatchParentPartname].map((element) =>
        element.PARTNAME === product?.PARTNAME
          ? { ...element, quantity: Number(currentQuantity) }
          : { ...element }
      );

      dispatch(
        setCartDetails({
          mixMatchItems: {
            ...mixMatchItems,
            [mixMatchParentPartname]: updatedMixMatch,
          },
        })
      );
    }

    if (product?.discounts && product?.discounts.length > 0) {
      // HANDLE MIXMATCH ITEM UPDATE
      const allCalculatedMixMatch = mixMatchItems[
        mixMatchParentPartname
      ].reduce((total, item) => total + Number(item?.quantity || 1), 0);
      const filterMixMatchDiscounts = product?.discounts.filter(
        (discount) =>
          Number(discount?.DEXT_OFFERCODE) === 4 &&
          Number(discount?.OFFERQTY) <= Number(allCalculatedMixMatch)
      );

      const sortedFilterMixMatchDiscounts = filterMixMatchDiscounts.sort(
        (a, b) => Number(b?.OFFERQTY) - Number(a?.OFFERQTY)
      );
      const selectedMixMatchDiscount =
        sortedFilterMixMatchDiscounts &&
        sortedFilterMixMatchDiscounts.length > 0
          ? sortedFilterMixMatchDiscounts[0]
          : undefined;

      if (selectedMixMatchDiscount) {
        const allDiscount = selectedMixMatchDiscount?.discounts;

        const allMixMatchItems = mixMatchItems[mixMatchParentPartname];

        const { discountApplicableonMixMatch } = await getMixMatchDiscount(
          allDiscount,
          allMixMatchItems,
          selectedMixMatchDiscount
        );
        const allPartMixMatchDiscount = {
          ...mixMatchDiscount,
          [PARTNAME]: discountApplicableonMixMatch,
        };
        const totalValue = Object.values(allPartMixMatchDiscount).reduce(
          (total, value) => total + value,
          0
        );

        dispatch(
          setMixMatchDiscount({
            totalMixMatchDiscount: totalValue,
            mixMatchDiscount: allPartMixMatchDiscount,
          })
        );
        toast.success("Cart updated successfully!");
      }
    }
  };

  const handleChangeMixMatchQuantity = (row, value) => {
    const matchedState = mixMatchItems?.[rowSearch?.PARTNAME];
    const found = matchedState
      ? matchedState.find((element) => element.PARTNAME == row?.PARTNAME)
      : null;
    if (found) {
      // Right now added this, but I want to update product with proper discount and all validation
      handleUpdateCart(row, value);
      // toast.error("Product already added!");
      // return;
    }
    const updatedMixMatch = mixMatch.map((item) => {
      if (item?.PARTNAME === row?.PARTNAME) {
        return {
          ...item,
          quantity: Number(value) || 1,
        };
      }
      return item;
    });
    setMixMatch(updatedMixMatch);
  };

  const handleIncreaseMixMatchQuantity = (row) => {
    const matchedState = mixMatchItems?.[rowSearch?.PARTNAME];
    const found = matchedState
      ? matchedState.find((element) => element.PARTNAME == row?.PARTNAME)
      : null;
    if (found) {
      // Right now added this, but I want to update product with proper discount and all validation
      const value = Number(row?.quantity) + 1;
      handleUpdateCart(row, value);
      // toast.error("Product already added!");
      // return;
    }
    const updatedMixMatch = mixMatch.map((item) => {
      if (item?.PARTNAME === row?.PARTNAME) {
        return {
          ...item,
          quantity: Number(item?.quantity) + 1,
        };
      }
      return item;
    });
    setMixMatch(updatedMixMatch);
  };

  const handleDecreaseMixMatchQuantity = (row) => {
    const matchedState = mixMatchItems?.[rowSearch?.PARTNAME];
    const found = matchedState
      ? matchedState.find((element) => element.PARTNAME == row?.PARTNAME)
      : null;
    if (found) {
      // Right now added this, but I want to update product with proper discount and all validation
      const value = Number(row?.quantity) - 1;
      handleUpdateCart(row, value);
      // toast.error("Product already added!");
      // return;
    }
    const updatedMixMatch = mixMatch.map((item) => {
      if (item?.PARTNAME === row?.PARTNAME) {
        return {
          ...item,
          quantity:
            Number(item?.quantity) === 1 ? 1 : Number(item?.quantity) - 1,
        };
      }
      return item;
    });
    setMixMatch(updatedMixMatch);
  };

  // ADD TO CART FROM SINGLE UNIT MUTIPLE ITEM
  const handleSingleUnitWithOffer = (row) => {
    const discounts = rowSearch?.discounts;
    const filteredDiscount =
      discounts?.length > 0
        ? discounts.filter(
            (item) =>
              item?.DEXT_OFFERCODE !== "4" &&
              Number(item?.OFFERQTY) <= Number(row?.quantity)
          )
        : [];

    const sortedDiscount =
      filteredDiscount?.length > 0
        ? filteredDiscount.sort(
            (a, b) => Number(b?.OFFERQTY) - Number(a?.OFFERQTY)
          )
        : [];

    const discount = sortedDiscount[0];
    if (discount) {
      const payload = {
        row,
        setShowCart,
        cartList,
        selectedDiscount: discount,
        freeItemQuantity,
        dispatch,
        singleUnitWithOffer: true,
      };
      handleAddToCart(payload);
    } else {
      const payload = {
        row,
        setShowCart,
        cartList,
        dispatch,
        singleUnit: true,
      };
      handleAddToCart(payload);
    }
    handleClose();
  };

  //handle user pressing escape button
  const handleEscape = (event) => {
    if (event.keyCode === 27) {
      handleCloseModal();
    }
  };

  // Clicked outside the modal
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      handleCloseModal();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleEscape);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("keydown", handleEscape);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (rowSearch !== undefined) {
    return (
      <Modal
        show={show}
        onClick={handleClickOutside}
        onHide={handleClose}
        dialogClassName="modal-wide"
        style={{ zIndex: 99999999999 }}
      >
        <div ref={modalRef}>
          <Modal.Header closeButton>
            <Modal.Title>Product Discount Info</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="border-b">
              <p className="text-md font-bold px-1">
                {popupModalDiscount?.rowSearch?.PARTNAME}
              </p>
              <p className="text-md px-1">
                {popupModalDiscount?.rowSearch?.PARTDES}
              </p>
            </div>
            {currentBody === 1 && (
              <>
                {rowSearch.hasOwnProperty("discounts") &&
                  rowSearch.discounts.map((discount, index) => (
                    <>
                      <Container>
                        <Row>
                          <div
                            class="d-flex justify-content-between px-padding flex-wrap items-center gap-2"
                            key={index}
                          >
                            {discount.hasOwnProperty("DEXT_OFFERCODE") &&
                              discount.DEXT_OFFERCODE == "4" && (
                                <>
                                  <p>Buy: {discount.OFFERDES}</p>
                                  <Button
                                    variant="primary"
                                    onClick={() =>
                                      handleSwipe(
                                        "left",
                                        rowSearch,
                                        discount.OFFERID,
                                        discount
                                      )
                                    }
                                  >
                                    <p>
                                      List of Mix&Match
                                      <ProductionQuantityLimitsIcon />
                                    </p>
                                  </Button>
                                  {/* </div> */}
                                </>
                              )}
                            {discount.hasOwnProperty("DEXT_OFFERCODE") &&
                              discount.DEXT_OFFERCODE !== "4" && (
                                <>
                                  <p>Buy: {discount.OFFERDES}</p>
                                  <Button
                                    variant="primary"
                                    className="bg-kedifapgreen-200 hover:bg-kedifapred-700 text-white p-3 rounded-3xl shadow-lg"
                                    onClick={() => {
                                      const payload = {
                                        row: rowSearch,
                                        setShowCart: setShowCart,
                                        cartList,
                                        selectedDiscount: discount,
                                        freeItemQuantity,
                                        dispatch,
                                      };
                                      handleAddToCart(payload);
                                    }}
                                    style={{
                                      backgroundColor: "#db2d2d",
                                      backgroundColor: "#db2d2d",
                                      width: "30px",
                                      fontSize: "15px",
                                      height: "30px",
                                    }}
                                  >
                                    <FaCartArrowDown
                                      style={{
                                        right: "8px",
                                        bottom: "8px",
                                        position: "relative",
                                      }}
                                    />
                                  </Button>
                                </>
                              )}
                          </div>
                        </Row>
                      </Container>
                    </>
                  ))}
                <Container>
                  <Row>
                    <div class="d-flex justify-content-between px-padding flex-wrap items-center gap-2">
                      <p>Add single unit</p>
                      <div className="flex gap-2">
                        <div className="flex gap-1">
                          <button
                            style={{
                              width: "38px",
                              borderRadius: "5px",
                              border: "1px solid #ccc",
                              padding: "2px",
                              fontSize: "1.2rem",
                              textAlign: "center",
                              fontWeight: "bold",
                              backgroundColor: "#f2f2f2",
                            }}
                            onClick={() => {
                              if (currentSelectedQuantity === 1) return;
                              setCurrentSelectedQuantity((prev) => prev - 1);
                            }}
                          >
                            -
                          </button>
                          <input
                            type="number"
                            value={currentSelectedQuantity}
                            onChange={(e) => {
                              setCurrentSelectedQuantity(e.target.value);
                            }}
                            style={{
                              width: "50px",
                              borderRadius: "5px",
                              border: "1px solid #ccc",
                              padding: "5px",
                              fontSize: "1rem",
                              textAlign: "center",
                            }}
                          />
                          <button
                            style={{
                              width: "38px",
                              borderRadius: "5px",
                              border: "1px solid #ccc",
                              padding: "3px",
                              fontSize: "1.1rem",
                              textAlign: "center",
                              fontWeight: "bold",
                              backgroundColor: "#f2f2f2",
                            }}
                            onClick={() => {
                              setCurrentSelectedQuantity((prev) => prev + 1);
                            }}
                          >
                            +
                          </button>
                        </div>

                        <Button
                          className="bg-kedifapgreen-200 hover:bg-kedifapred-700 text-white p-3 rounded-3xl shadow-lg"
                          onClick={() => {
                            const modifiedRow = productOriginal.original;
                            modifiedRow.quantity = Number(
                              currentSelectedQuantity || 1
                            );
                            handleSingleUnitWithOffer(modifiedRow);
                          }}
                          style={{
                            width: "30px",
                            fontSize: "15px",
                            height: "30px",
                          }}
                        >
                          <FaCartArrowDown
                            style={{
                              right: "8px",
                              bottom: "8px",
                              position: "relative",
                            }}
                          />
                        </Button>
                      </div>
                    </div>
                  </Row>
                </Container>
              </>
            )}
            {currentBody === 2 && mixMatch.length !== 0 && (
              <>
                <p>
                  Number of items for this discount : {mixMatch[0].OFFERQTY}
                </p>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th style={{ textAlign: "center" }} colspan="6">
                        Mix & Match
                      </th>
                    </tr>
                    <tr>
                      <th>Code</th>
                      <th>Descirption</th>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th>Add to cart</th>
                    </tr>
                  </thead>
                  <tbody>
                    {mixMatch.map((item, index) => {
                      const matchedState = mixMatchItems?.[rowSearch?.PARTNAME];
                      const found = matchedState
                        ? matchedState.find(
                            (element) => element.PARTNAME == item?.PARTNAME
                          )
                        : null;

                      return (
                        <tr key={index}>
                          <td
                            style={
                              found
                                ? { color: "#0d6efd", fontWeight: "700" }
                                : null
                            }
                          >
                            {item.PARTNAME}
                          </td>
                          <td
                            style={
                              found
                                ? { color: "#0d6efd", fontWeight: "700" }
                                : null
                            }
                          >
                            {item.PARTDES}
                          </td>
                          <td
                            style={
                              found
                                ? { color: "#0d6efd", fontWeight: "700" }
                                : null
                            }
                          >
                            {item.WSPLPRICE}
                          </td>
                          <td
                            style={
                              found
                                ? {
                                    color: "#0d6efd",
                                    fontWeight: "700",
                                    textAlign: "center",
                                  }
                                : { textAlign: "center" }
                            }
                          >
                            {item.TBALANCE}
                          </td>

                          <td>
                            <div class="cart-item-controls gap-1">
                              <button
                                className="bg-kedifapgreen-200 hover:bg-kedifapred-700 text-white p-3 rounded-3xl shadow-lg"
                                onClick={() => {
                                  item.PARENT_PARTNAME = rowSearch?.PARTNAME;
                                  const payload = {
                                    row: rowSearch,
                                    setShowCart,
                                    cartList,
                                    dispatch,
                                    mixMatchItems:
                                      mixMatchItems[rowSearch?.PARTNAME] || [],
                                    selectedMixMatch: item,
                                    cartDiscount,
                                    mixMatchDiscount,
                                    freeItemQuantity,
                                  };
                                  handleAddToCart(payload);
                                }}
                                style={{
                                  backgroundColor: "#db2d2d",
                                  backgroundColor: "#db2d2d",
                                  width: "30px",
                                  fontSize: "15px",
                                  height: "30px",
                                }}
                              >
                                <FaCartArrowDown
                                  style={{
                                    right: "8px",
                                    bottom: "8px",
                                    position: "relative",
                                  }}
                                />
                              </button>
                              <div className="flex gap-1">
                                <button
                                  style={{
                                    width: "38px",
                                    borderRadius: "5px",
                                    border: "1px solid #ccc",
                                    padding: "2px",
                                    fontSize: "1.2rem",
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    backgroundColor: "#f2f2f2",
                                  }}
                                  onClick={() => {
                                    if (item?.quantity === 1) return;
                                    handleDecreaseMixMatchQuantity(item);
                                  }}
                                >
                                  -
                                </button>
                                <input
                                  type="number"
                                  value={item?.quantity}
                                  min={
                                    parseInt(item.DEXT_LOWSTOCKQTY) > 0
                                      ? item.DEXT_LOWSTOCKQTY
                                      : 1
                                  }
                                  max={item.stock}
                                  onChange={(e) => {
                                    handleChangeMixMatchQuantity(
                                      item,
                                      e.target.value
                                    );
                                  }}
                                  style={{
                                    width: "50px",
                                    borderRadius: "5px",
                                    border: "1px solid #ccc",
                                    padding: "5px",
                                    fontSize: "1rem",
                                    textAlign: "center",
                                  }}
                                />
                                <button
                                  style={{
                                    width: "38px",
                                    borderRadius: "5px",
                                    border: "1px solid #ccc",
                                    padding: "3px",
                                    fontSize: "1.1rem",
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    backgroundColor: "#f2f2f2",
                                  }}
                                  onClick={() => {
                                    handleIncreaseMixMatchQuantity(item);
                                  }}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <div className="text-center font-bold">
                  <p>Mix & Match Discount Progress</p>
                  <p
                    className={
                      Number(totalMixMatchAddedInCart) >=
                      Number(mixMatch[0]?.OFFERQTY)
                        ? "text-green-700"
                        : "w-full"
                    }
                  >
                    ({totalMixMatchAddedInCart} out of {mixMatch[0]?.OFFERQTY})
                  </p>
                </div>
              </>
            )}
            {currentBody === 2 && mixMatch.length == 0 && (
              <h2>No matching products available for this offer</h2>
            )}
          </Modal.Body>
          <Modal.Footer>
            {currentBody == 2 && (
              <Button
                variant="secondary"
                style={{ color: "black" }}
                onClick={() => handleSwipe("right")}
              >
                Back
              </Button>
            )}
            <Button
              variant="danger"
              style={{ color: "red" }}
              onClick={handleCloseModal}
            >
              Close
            </Button>
          </Modal.Footer>
        </div>
        <Toaster
          toastOptions={{
            duration: 5000,
          }}
        />
      </Modal>
    );
  }
}

export default ProductDiscountModal;
