import React, { useState, useEffect } from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CalculateMixTotal from "../Material-functions/calculateMixProgress";
import { toast } from "react-hot-toast";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import CartItem from "./CartItems";
import cartSession from "./cartSession";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Divider from "@mui/material/Divider";

// import handleCartSortBy from "./cartSort";
import { FetchB2B_PHCUSTONE, FetchPharmacies } from "../Api/pharmaciesApi";
import {
  postSavedTemplate,
  updateSavedTemplate,
} from "../Api/savedTemplateApi";
import moment from "moment";
import "./Cart.css";
import TemplateSaveModal from "./TemplateSaveModal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { vatDetailsObject } from "../../../constant/vatDetailsObject";
import {
  initialState,
  resetCartDetails,
  setCartDetails,
  setCartDiscount,
  setItemExtraDiscount,
  setItemExtraFree,
  setMixMatchDiscount,
} from "../../../store/reducer/cart";
import { getMixMatchDiscount } from "../Material-items/getMixMatchDiscount";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Cart = (props) => {
  const {
    showCart,
    setShowCart,
    cartItems,
    setCartItems,
    handleCartClose,
    handleCartClick,
    total,
    setTotal,
    cartTemplate,
    setCartTemplate,
    quantityInputValue,
    setQuantityInputValue,
    caluclateFlatTotal,
    caluclateDiscount,
    setDiscountAmount,
    productQuantity,
    setProductQuantity,
    discountLabel,
    setDiscountLabel,
    freeQuantity,
    setFreeQuantity,
    isVendorName,
    caluclateMixMatch,
    discountAmount,
    highlightStyle,
    setHighlightStyle,
    mixProgress,
    setMixProgress,
    setSelectedOffer,
  } = props;
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const cartDetails = useSelector((state) => state.cart);
  const {
    cartList,
    cartGrossTotal,
    cartDiscount,
    cartNetTotal,
    totalCartItem,
    totalFreeCartItem,
    totalMixMatchDiscount,
    freeItemQuantity,
    mixMatchItems,
    mixMatchDiscount,
    itemsExtraFree,
    itemsExtraDiscount,
    totalExtraFreeItemDiscount,
    totalExtraDiscount,
    totalExtraFreeItem,
  } = cartDetails;

  const query = new URLSearchParams(window.location.search);
  const isShowCart = query.get("showCart");
  const templateId = query.get("templateId");
  const userData = localStorage.getItem("userDetails");
  const userDetails = userData ? JSON.parse(userData) : undefined;
  const [creditDay, setCreditDay] = useState("");
  const [discount, setDiscount] = useState("");
  const [freeQty, setFreeQty] = useState("");
  const [custNote, setCustNote] = useState("");
  const [isOpenTemplatePopup, setIsOpenTemplatePopup] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [templateName, setTemplateName] = useState(
    query.get("templateName") || ""
  );
  const [pharmacyValue, setPharmacyValue] = useState("");
  const [freeGivenItemPrices, setFreeGivenItemPrices] = useState(0);
  const sessionCart = cartSession.getItems();
  // const calculatedDiscount = caluclateDiscount(sessionCart);

  useEffect(() => {
    let extraFreeItemDiscount = 0;
    for (const key in freeItemQuantity) {
      if (freeItemQuantity.hasOwnProperty(key)) {
        const item = freeItemQuantity[key];
        const quantity = Number(item.quantity);
        const price = Number(item.WSPLPRICE);
        extraFreeItemDiscount += quantity * price;
      }
    }
    setFreeGivenItemPrices(extraFreeItemDiscount);
  }, [cartDetails]);


  const handleQuantityChange = async (currentQuantity, item) => {
    let product = { ...item };
    const PARTNAME = product?.PARTNAME;
    // Update Cart Item with new quantity
    product.quantity = currentQuantity;
    const updatedCartList = cartList.map((item) => {
      if (item?.PARTNAME === PARTNAME) {
        return {
          ...item,
          quantity: Number(currentQuantity),
        };
      }
      return item;
    });
    dispatch(
      setCartDetails({
        cartList: updatedCartList,
      })
    );

    const mixMatchParentPartname =
      product?.selectedDiscount?.DEXT_OFFERPARTNAME;

    const isApplicableMixMatchDiscount = () =>
      mixMatchItems[mixMatchParentPartname] &&
      mixMatchItems[mixMatchParentPartname].length > 1
        ? true
        : false;

    let updatedMixMatch = mixMatchItems[mixMatchParentPartname];
    if (mixMatchItems[mixMatchParentPartname]) {
      updatedMixMatch = mixMatchItems[mixMatchParentPartname].map((element) =>
        element.PARTNAME === product?.PARTNAME
          ? { ...element, quantity: Number(currentQuantity) }
          : { ...element }
      );

      dispatch(
        setCartDetails({
          mixMatchItems: {
            ...mixMatchItems,
            [mixMatchParentPartname]: updatedMixMatch,
          },
        })
      );
    }

    if (product?.discounts && product?.discounts.length > 0) {
      const filterDiscount = product?.discounts.filter(
        (discount) =>
          Number(discount?.DEXT_OFFERCODE) !== 4 &&
          Number(discount?.OFFERQTY) <= Number(currentQuantity)
      );
      if (!filterDiscount || filterDiscount.length === 0) {
        const { [PARTNAME]: _, ...updatedFreeItemQuantity } = freeItemQuantity;
        dispatch(
          setCartDetails({
            freeItemQuantity: updatedFreeItemQuantity,
          })
        );
      } else {
        const sortedFilterDiscount = filterDiscount.sort(
          (a, b) => Number(b?.OFFERQTY) - Number(a?.OFFERQTY)
        );
        const selectedDiscount =
          sortedFilterDiscount && sortedFilterDiscount.length > 0
            ? sortedFilterDiscount[0]
            : undefined;

        let totalFreeQty = 0;
        let totalDiscount = 0;

        if (
          sortedFilterDiscount &&
          sortedFilterDiscount.length > 0 &&
          !isApplicableMixMatchDiscount()
        ) {
          let remainingQty = Number(currentQuantity);

          sortedFilterDiscount.forEach((discount) => {
            if (remainingQty >= Number(discount.OFFERQTY)) {
              const applicableQty = Math.floor(
                remainingQty / Number(discount.OFFERQTY)
              );
              totalFreeQty += applicableQty * Number(discount.FREEQTY);
              remainingQty -= applicableQty * Number(discount.OFFERQTY);
            }
          });

          // Calculate discount regardless of FREEQTY value
          remainingQty = Number(currentQuantity);
          sortedFilterDiscount.forEach((discount) => {
            if (remainingQty >= Number(discount.OFFERQTY)) {
              const applicableQty = Math.floor(
                remainingQty / Number(discount.OFFERQTY)
              );
              totalDiscount +=
                applicableQty *
                ((Number(product?.WSPLPRICE) * Number(discount.DISCOUNT || 0)) /
                  100) *
                Number(discount.OFFERQTY);
              remainingQty -= applicableQty * Number(discount.OFFERQTY);
            }
          });

          // Apply discount for remaining quantity with lower tier discounts
          if (remainingQty > 0) {
            sortedFilterDiscount
              .slice()
              .reverse()
              .forEach((discount) => {
                if (remainingQty >= Number(discount.OFFERQTY)) {
                  const applicableQty = Math.floor(
                    remainingQty / Number(discount.OFFERQTY)
                  );
                  totalDiscount +=
                    applicableQty *
                    ((Number(product?.WSPLPRICE) *
                      Number(discount.DISCOUNT || 0)) /
                      100) *
                    Number(discount.OFFERQTY);
                  remainingQty -= applicableQty * Number(discount.OFFERQTY);
                }
              });
          }
          dispatch(
            setCartDetails({
              freeItemQuantity: {
                ...freeItemQuantity,
                [product.PARTNAME]: {
                  quantity: totalFreeQty,
                  WSPLPRICE: product?.WSPLPRICE,
                  OFFERQTY: Number(currentQuantity),
                  selectedDiscount: selectedDiscount, // In Percentage
                  totalDiscount: totalDiscount,
                },
              },
            })
          );
        }
      }

      // HANDLE MIXMATCH ITEM UPDATE
      const allCalculatedMixMatch =
        updatedMixMatch &&
        updatedMixMatch.reduce(
          (total, item) => total + Number(item?.quantity || 1),
          0
        );

      const filterMixMatchDiscounts = product?.discounts.filter(
        (discount) =>
          Number(discount?.DEXT_OFFERCODE) === 4 &&
          Number(discount?.OFFERQTY) <= Number(allCalculatedMixMatch)
      );

      const sortedFilterMixMatchDiscounts = filterMixMatchDiscounts.sort(
        (a, b) => Number(b?.OFFERQTY) - Number(a?.OFFERQTY)
      );
      const selectedMixMatchDiscount =
        sortedFilterMixMatchDiscounts &&
        sortedFilterMixMatchDiscounts.length > 0
          ? sortedFilterMixMatchDiscounts[0]
          : undefined;

      if (selectedMixMatchDiscount) {
        const allDiscount = selectedMixMatchDiscount?.discounts;

        const allMixMatchItems = updatedMixMatch;

        const { discountApplicableonMixMatch } = await getMixMatchDiscount(
          allDiscount,
          allMixMatchItems,
          selectedMixMatchDiscount
        );
        const allPartMixMatchDiscount = {
          ...mixMatchDiscount,
          [mixMatchParentPartname]: discountApplicableonMixMatch,
        };
        const totalValue = Object.values(allPartMixMatchDiscount).reduce(
          (total, value) => total + value,
          0
        );

        dispatch(
          setMixMatchDiscount({
            totalMixMatchDiscount: totalValue,
            mixMatchDiscount: allPartMixMatchDiscount,
          })
        );
      } else {
        const { [mixMatchParentPartname]: _, ...updatedMixMatchDiscount } =
          mixMatchDiscount;
        const totalValue = Object.values(updatedMixMatchDiscount).reduce(
          (total, value) => total + Number(value || 0),
          0
        );
        dispatch(
          setMixMatchDiscount({
            mixMatchDiscount: updatedMixMatchDiscount,
            totalMixMatchDiscount: totalValue,
          })
        );
      }
    }
  };

  const removeItem = (product) => {
    // REMOVE ITEM FROM THE LIST
    const PARTNAME = product?.PARTNAME;
    const updatedCart = cartList.filter((item) => item?.PARTNAME !== PARTNAME);

    if (freeItemQuantity[PARTNAME]) {
      let totalFreeItemsAmount = 0;

      if (
        freeItemQuantity[PARTNAME]?.selectedDiscount?.DEXT_OFFERCODE === "1"
      ) {
        totalFreeItemsAmount =
          Number(freeItemQuantity[PARTNAME]?.quantity) *
          Number(freeItemQuantity[PARTNAME]?.WSPLPRICE);
      } else {
        totalFreeItemsAmount =
          (Number(freeItemQuantity[PARTNAME]?.quantity) *
            Number(freeItemQuantity[PARTNAME]?.WSPLPRICE) *
            Number(freeItemQuantity[PARTNAME]?.selectedDiscount?.DISCOUNT)) /
          100;
      }

      // Create a new object without the PARTNAME key to maintain immutability
      const { [PARTNAME]: _, ...updatedFreeItemQuantity } = freeItemQuantity;

      dispatch(
        setCartDiscount({
          cartDiscount: Number(cartDiscount) - Number(totalFreeItemsAmount),
        })
      );

      dispatch(
        setCartDetails({
          cartList: updatedCart,
          freeItemQuantity: updatedFreeItemQuantity,
        })
      );
    } else if (mixMatchItems[product?.selectedDiscount?.DEXT_OFFERPARTNAME]) {
      const selectedDiscountPartName =
        product?.selectedDiscount?.DEXT_OFFERPARTNAME;
      const filterMixMatchItems = mixMatchItems[
        selectedDiscountPartName
      ].filter((item) => item?.PARTNAME !== product?.PARTNAME);

      const PARTNAME = selectedDiscountPartName;
      const deductedDiscount = mixMatchDiscount[PARTNAME];

      // Create a new object for mixMatchDiscount without the deleted PARTNAME
      const { [PARTNAME]: _, ...updatedMixMatchDiscount } = mixMatchDiscount;

      dispatch(
        setCartDetails({
          cartList: updatedCart,
          mixMatchItems: { ...mixMatchItems, [PARTNAME]: filterMixMatchItems },
          mixMatchDiscount: updatedMixMatchDiscount,
          totalMixMatchDiscount: totalMixMatchDiscount - deductedDiscount,
        })
      );
    } else {
      dispatch(
        setCartDetails({
          cartList: updatedCart,
          freeItemQuantity: freeItemQuantity,
        })
      );
    }

    const updatedFreeItems = { ...itemsExtraFree };
    delete updatedFreeItems[PARTNAME];
    dispatch(setItemExtraFree({ itemsExtraFree: updatedFreeItems }));

    const updatedFreeDiscount = { ...itemsExtraDiscount };
    delete updatedFreeDiscount[PARTNAME];
    dispatch(setItemExtraDiscount({ itemsExtraDiscount: updatedFreeDiscount }));
  };

  const clearCart = () => {
    dispatch(setCartDetails(initialState));
  };

  useEffect(() => {
    if (isShowCart) {
      setShowCart(true);
    }
  }, [isShowCart]);

  // const sendOrder = async (
  //   order,
  //   isVendorName,
  //   freeQuantity,
  //   pharmacyValue,
  //   custNote
  // ) => {
  //   if (!pharmacyValue && isVendorName) {
  //     toast.error("Please Select Pharmacy");
  //     return;
  //   }
  //   const loadingToast = toast.loading("Sending order...");

  //   let userFullId = localStorage.getItem("userId");
  //   let [userId, dCode] = isVendorName
  //     ? pharmacyValue?.Code.split("-")
  //     : userFullId.split("-");
  //   let userDesc = localStorage.getItem("userDesc");
  //   console.log(isVendorName, "isVendorName in sendOrder.js");
  //   console.log(order, "order.()");
  //   if (order.length == 0) {
  //     toast.error("Cant send empty cart");
  //   }
  //   console.log(order[0], "orderitem");
  //   const sessionFreeQuantity = await cartSession.getSessionFreeQuantity();
  //   const cartQuantity = await cartSession.getSessionQuantity();
  //   const sessionCartFreeQuantity = localStorage.getItem("kediCartFreeQuantity")
  //     ? JSON.parse(localStorage.getItem("kediCartFreeQuantity"))
  //     : {};
  //   const productsinOrder = order.map((obj, index) => ({
  //     PARTNAME: obj.PARTNAME,
  //     // PDES: obj.PARTDES,
  //     TQUANT: !obj?.quantity
  //       ? cartQuantity[obj.PARTNAME] || 1
  //       : Number(obj?.quantity),
  //     DEXT_REQUESTEDQTY: !obj?.quantity
  //       ? cartQuantity[obj.PARTNAME] || 1
  //       : Number(obj?.quantity),
  //     DEXT_FREEQTY: isVendorName
  //       ? Number(sessionCartFreeQuantity[obj.PARTNAME])
  //       : Number(sessionFreeQuantity[obj.PARTNAME] || 0),
  //     // freeQuantity[obj.PARTNAME] > 0 ? freeQuantity[obj.PARTNAME] : 0,
  //     PERCENT: isVendorName ? Number(itemsExtraDiscount[obj.PARTNAME]) : 0,
  //     // PERCENT: isVendorName ? discount : 0,
  //     DEXT_CONFIRMORDER: index == order.length - 1 ? "Y" : "",
  //   }));

  //   let today = moment().format();
  //   let [vendorUserId, vendorDCode] = isVendorName.split("-");

  //   const orderObject = {
  //     CUSTNAME: userId,
  //     // CDES: userDesc,
  //     CURDATE: today,
  //     ...(isVendorName && { DEXT_SUPPNAME: vendorUserId || isVendorName }),
  //     ...(isVendorName && { DEXT_SUPPDES: userDesc }),
  //     // DEXT_SUPPNAME: "V1239",
  //     // DEXT_SUPPDES: "4MORE LTD 2",
  //     DCODE: !isVendorName ? dCode : "",
  //     DEXT_CUSTOMERREMARKS: !isVendorName ? custNote : "",
  //     DEXT_VENTORREMARKS: isVendorName ? custNote : "",
  //     DEXT_SUBMISSIONDATE: today,
  //     PAYCODE: isVendorName ? creditDay : "20",
  //     DEXT_B2CONTACT: isVendorName
  //       ? Number(pharmacyValue?.PHONE)
  //       : Number(userDetails?.PHONE),
  //     B2B_ORDERITEMS_SUBFORM: productsinOrder,
  //   };

  //   //send order

  //   //const url = "http://localhost:8000/order";
  //   //const url = "https://kedifap-portal.com2go.co/order";
  //   const url = new URL(`${process.env.REACT_APP_API_URL}/order`);

  //   try {
  //     const response = await axios.post(url, orderObject);
  //     console.log(response, "response");
  //     toast.dismiss(loadingToast);
  //     toast.success("Order Sent Successfully, Thank you!!");
  //     setCartItems([]);
  //     setTotal(0);
  //     setProductQuantity({});
  //     setHighlightStyle([]);
  //     setCustNote("");
  //     localStorage.setItem("kediCart", []);
  //     localStorage.setItem("kediCartTotal", []);
  //     localStorage.setItem("kediCartDiscount", 0);
  //     localStorage.setItem("kediCartQuantity", 0);
  //     localStorage.setItem("kediCartFreeQuantity", []);
  //     localStorage.setItem("kediCartHighlight", []);
  //     localStorage.setItem("kediCartDiscountLabel", null);
  //     localStorage.removeItem("currentSessionCartData");
  //   } catch (error) {
  //     toast.dismiss(loadingToast);
  //     toast.error(
  //       "There was an issue making your order, please contact support."
  //     );
  //     console.error(error);
  //   } finally {
  //     setShowCart(false);
  //     navigate("/app/catalogue");
  //     setDiscount("");
  //     setCreditDay("");
  //     setFreeQty("");
  //     setCustNote("");
  //   }
  // };

  const sendOrderNew = async () => {
    if (!pharmacyValue && isVendorName) {
      toast.error("Please Select Pharmacy");
      return;
    }
    const loadingToast = toast.loading("Sending order...");

    let userFullId = localStorage.getItem("userId");
    let [userId, dCode] = isVendorName
      ? pharmacyValue?.Code.split("-")
      : userFullId.split("-");
    let userDesc = localStorage.getItem("userDesc");

    if (cartList.length == 0) {
      toast.error("Can't send empty cart");
    }

    const productsinOrder = cartList.map((obj, index) => ({
      PARTNAME: obj.PARTNAME,
      TQUANT: obj?.quantity,
      DEXT_REQUESTEDQTY: obj?.quantity,
      DEXT_FREEQTY: isVendorName
        ? Number(itemsExtraFree[obj.PARTNAME]?.quantity || 0) +
          Number(freeItemQuantity[obj.PARTNAME]?.quantity || 0)
        : Number(freeItemQuantity[obj.PARTNAME]?.quantity || 0),
      PERCENT: isVendorName
        ? Number(itemsExtraDiscount[obj.PARTNAME]?.percent)
        : 0,
      DEXT_CONFIRMORDER: index == cartList.length - 1 ? "Y" : "",
    }));

    let today = moment().format();
    let [vendorUserId, vendorDCode] = isVendorName.split("-");

    const orderObject = {
      CUSTNAME: userId,
      CURDATE: today,
      ...(isVendorName && { DEXT_SUPPNAME: vendorUserId || isVendorName }),
      ...(isVendorName && { DEXT_SUPPDES: userDesc }),
      DCODE: !isVendorName ? dCode : dCode,
      DEXT_CUSTOMERREMARKS: !isVendorName ? custNote : "",
      DEXT_VENTORREMARKS: isVendorName ? custNote : "",
      DEXT_SUBMISSIONDATE: today,
      PAYCODE: isVendorName ? creditDay : null,
      DEXT_B2CONTACT: isVendorName
        ? Number(pharmacyValue?.PHONE)
        : Number(userDetails?.PHONE),
      B2B_ORDERITEMS_SUBFORM: productsinOrder,
    };

    setIsSubmitting(true);

    const url = new URL(`${process.env.REACT_APP_API_URL}/order`);

    try {
      axios.defaults.timeout = 240000;
      const response = await axios.post(url, orderObject);
      toast.dismiss(loadingToast);
      toast.success("Order Sent Successfully, Thank you!!");
      dispatch(resetCartDetails());
      setShowCart(false);
      navigate("/app/catalogue");
      setDiscount("");
      setCreditDay("");
      setFreeQty("");
      setCustNote("");
      setPharmacyValue("");
      setIsSubmitting(false);
      const finalUrl = new URL(`${process.env.REACT_APP_API_URL}/order-complete?orderNumber=${response?.data?.ORDNAME}`);
      axios.post(finalUrl);
    } catch (error) {
      toast.dismiss(loadingToast);
      toast.error(
        "There was an issue making your order, please contact support."
      );
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleNoteChange = (event) => {
    setCustNote(event.target.value);
  };

  const [pharmacies, setPharmacies] = useState([]);

  useEffect(() => {
    if (!isVendorName) return;
    const fetchData = async () => {
      let userFullId = localStorage.getItem("userId");
      let [userId, dCode] = userFullId.split("-");
      console.log(dCode, isVendorName);
      try {
        if (dCode && isVendorName) {
          const result = await FetchB2B_PHCUSTONE();
          setPharmacies(result);
        } else {
          const result = await FetchPharmacies(userId);
          setPharmacies(result);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [isVendorName]);

  const [sortBy, setSortBy] = useState("default");
  const [isSaving, setIsSaving] = useState(false);

  const renderCartItem = (item, index) => (
    <CartItem
      discountLabel={cartSession.getSessionDiscountLabel()}
      quantityInputValue={cartSession.getSessionQuantity()}
      handleQuantityChange={handleQuantityChange}
      setQuantityInputValue={setQuantityInputValue}
      removeItem={removeItem}
      item={item}
      index={index}
      sortBy={sortBy}
      isVendorName={isVendorName}
    />
  );

  const saveCartInTemplate = async () => {
    const userId = localStorage.getItem("userId");
    const body = {
      userId,
      name: templateName,
      cartData: JSON.stringify(cartDetails),
      totalItems: cartList.length,
      amount: Number(cartGrossTotal).toFixed(2),
      discount: Number(cartDiscount).toFixed(2),
      quantity: totalCartItem,
    };
    try {
      setIsSaving(true);
      const savedTemplateResponse = await postSavedTemplate(body);
      if (savedTemplateResponse?.message) {
        toast.success(savedTemplateResponse?.message);
        handleCartClose();
        setIsOpenTemplatePopup(false);
      } else {
        toast.error(savedTemplateResponse?.error);
      }
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setIsSaving(false);
    }
  };

  const updateTemplate = async () => {
    if (!templateId) return;
    const userId = localStorage.getItem("userId");
    const body = {
      userId,
      name: templateName,
      cartData: JSON.stringify(cartDetails),
      totalItems: cartList.length,
      amount: Number(cartGrossTotal).toFixed(2),
      discount: Number(cartDiscount).toFixed(2),
      quantity: totalCartItem,
    };
    try {
      setIsSaving(true);
      const savedTemplateResponse = await updateSavedTemplate(body, templateId);
      if (savedTemplateResponse?.message) {
        toast.success(savedTemplateResponse?.message);
        handleCartClose();
        setIsOpenTemplatePopup(false);
      } else {
        toast.error(savedTemplateResponse?.error);
      }
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setIsSaving(false);
    }
  };

  const handleTemplateSubmit = () => {
    if (templateId) {
      updateTemplate();
    } else {
      saveCartInTemplate();
    }
  };

  const handleCloseTemplatePopup = () => {
    setIsOpenTemplatePopup(false);
  };

  const handleOpenTemplatePopup = () => {
    if (window.innerWidth < 1024) {
      setSearchParams("");
    }
    setShowCart(false);
    setIsOpenTemplatePopup(true);
    const template = query.get("templateName") || "";
    setTemplateName(template);
  };

  // CART SORTING
  const handleCartSortBy = () => {
    switch (sortBy) {
      case "byDesc":
        const byDescCart = [...cartList].sort((a, b) =>
          a.PARTDES.localeCompare(b.PARTDES)
        );
        dispatch(setCartDetails({ cartList: byDescCart }));
        break;
      case "default":
        dispatch(setCartDetails({ cartList: cartList }));
        break;
      case "byValue":
        const byValueCart = [...cartList].sort((a, b) => {
          const productA = Number(a?.quantity || 1) * parseFloat(a.WSPLPRICE);
          const productB = Number(b?.quantity || 1) * parseFloat(b.WSPLPRICE);
          return productA - productB;
        });
        dispatch(setCartDetails({ cartList: byValueCart }));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!sortBy) return;
    handleCartSortBy();
  }, [sortBy]);

  return (
    <div>
      <button class="basket" onClick={handleCartClick}>
        <span className="icon">{cartList ? cartList?.length : 0}</span>
      </button>
      {showCart && (
        <div class="cart-container">
          <div class="flex items-center gap-2 justify-between">
            <div class="">
              <h2 class="cart-title">Cart</h2>
            </div>
            <div class="">
              <button class="close-icon" onClick={handleCartClose}></button>
            </div>
          </div>
          <div className="cart-list">
            <Box
              sx={{
                minWidth: 100,
                padding: "15px",
                paddingLeft: "5px",
                width: "80%",
              }}
            >
              <select
                name="sort"
                id="sort"
                className="border p-2 rounded-sm outline-none w-full max-w-[340px]"
                value={sortBy}
                onChange={(e) => {
                  setSortBy(e.target.value);
                }}
              >
                <option value="">Sort</option>
                <option value="byDesc">Part Description</option>
                <option value="byValue">Total Value</option>
              </select>
            </Box>
            <Divider />
            <table className="w-full border-none">
              {window.innerWidth > 1024 && (
                <tr>
                  <th className="pb-2">Part Name</th>
                  <th className="pb-2">Code</th>
                  <th className="pb-2">Wholesale</th>
                  <th className="pb-2">VAT%</th>
                  <th className="pb-2">VAT</th>
                  <th className="pb-2">Price</th>
                  <th className="pb-2">Total</th>
                  <th className="text-center pb-2">Quantity</th>
                </tr>
              )}
              {cartList &&
                cartList.map((item, index) => renderCartItem(item, index))}
            </table>
          </div>
          <div class="cart-item-details">
            Number of items : {totalCartItem}
            {(totalFreeCartItem || totalExtraFreeItem) > 0 && (
              <span>+ {totalFreeCartItem + totalExtraFreeItem} Free items</span>
            )}
          </div>
          <div className="px-2 mb-3">
            <div className="flex w-9/12 justify-between">
              <p className="text-md font-bold">Gross Total:</p>
              <p className="text-md font-bold text-right">
                {(
                  Number(cartGrossTotal || 0) + Number(freeGivenItemPrices)
                ).toFixed(2)}
              </p>
            </div>
            <div className="flex w-9/12 justify-between">
              <p className="text-md font-bold">Discount:</p>
              <p className="text-md font-bold text-right">
                {(
                  Number(cartDiscount || 0) +
                  Number(totalMixMatchDiscount || 0) +
                  Number(totalExtraDiscount || 0)
                ).toFixed(2)}
              </p>
            </div>
            <div className="flex w-9/12 justify-between">
              <p className="text-md font-bold">Net Total:</p>
              <p className="text-md font-bold text-right">
                {(
                  Number(cartGrossTotal || 0) +
                  Number(freeGivenItemPrices) -
                  Number(cartDiscount || 0) -
                  Number(totalMixMatchDiscount || 0) -
                  Number(totalExtraDiscount || 0)
                ).toFixed(2)}
              </p>
            </div>
            {window.innerWidth > 1024 && (
              <div className="flex w-9/12 justify-between">
                <p className="text-sm italic">
                  Prices for indicative purposes only{" "}
                </p>
              </div>
            )}
          </div>
          {isVendorName !== "" && (
            <>
              <Autocomplete
                className="pharmacy-box"
                disablePortal
                id="combo-box-demo"
                options={pharmacies}
                getOptionLabel={(option) =>
                  `${option?.label} - ${option?.name}`
                }
                sx={{ width: 340 }}
                renderInput={(params) => (
                  <TextField {...params} label="Pharmacy" />
                )}
                onChange={(event, newValue) => {
                  setPharmacyValue(newValue);
                }}
              />
              {userDetails?.DEXT_PAYWORD === "Y" && (
                <FormControl fullWidth sx={{ my: 1.5 }}>
                  <Autocomplete
                    className="pharmacy-box"
                    disablePortal
                    id="combo-box-demo"
                    options={[
                      { value: "", label: "None" },
                      { value: "90", label: "90 Days" },
                      { value: "120", label: "120 Days" },
                      { value: "180", label: "180 Days" },
                    ]}
                    getOptionLabel={(option) => option?.label}
                    sx={{ width: 340 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Credit" />
                    )}
                    onChange={(event, newValue) => {
                      setCreditDay(newValue?.value);
                    }}
                  />
                  {/* <InputLabel id="credit-day">Credit</InputLabel>
                  <Select
                    labelId="credit-day"
                    id="credit-day-select"
                    value={creditDay}
                    label="Credit"
                    onChange={(e) => {
                      setCreditDay(e.target.value);
                    }}
                    style={{ zIndex: "99999999999 !important" }}
                  >
                    <MenuItem value="">None</MenuItem>
                    <MenuItem value="90">90 days</MenuItem>
                    <MenuItem value="120">120 Days</MenuItem>
                    <MenuItem value="180">180 Days</MenuItem>
                  </Select> */}
                </FormControl>
              )}
            </>
          )}
          <TextField
            className="cust-notes"
            label="Your notes"
            variant="outlined"
            multiline
            fullWidth="true"
            rows={4}
            value={custNote}
            onChange={handleNoteChange}
          />
          <div className="d-flex mt-2">
            <button
              onClick={handleOpenTemplatePopup}
              className="btn btn-info cart-clear-btn"
            >
              {templateId ? "Update Template" : "Save template"}
            </button>
          </div>
          <div className="d-flex justify-content-between cart-end">
            <button
              onClick={() => clearCart()}
              className="btn btn-danger cart-clear-btn"
            >
              Clear Cart
            </button>
            <button
              onClick={() => {
                if (isVendorName && !pharmacyValue) {
                  // toast.error("Please select pharmacy");
                  alert("Please Select Pharmacy");
                  return;
                }
                if (window.innerWidth < 1024) {
                  setShowCart(false);
                  setSearchParams("");
                }
                toast((t) => (
                  <span style={{ zIndex: 99999999999999 }}>
                    Θέλετε να αποσταλεί η παραγγελία σας;
                    <br></br>
                    <Box sx={{ flexGrow: 1, padding: "10px" }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Item>
                            <button
                              onClick={() => {
                                sendOrderNew();
                                toast.dismiss(t.id);
                              }}
                            >
                              Send order
                            </button>
                          </Item>
                        </Grid>
                        <Grid item xs={6}>
                          <Item>
                            {" "}
                            <button onClick={() => toast.dismiss(t.id)}>
                              Cancel
                            </button>
                          </Item>
                        </Grid>
                      </Grid>
                    </Box>
                  </span>
                ));
              }}
              className="btn btn-primary "
              disabled={!cartList || cartList.length === 0 || isSubmitting}
            >
              Send order
            </button>
          </div>
          {/* <div class="cart-template">
            <button
              onClick={() => saveCart(cartItems)}
              class="btn btn-secondary"
            >
              Save Cart
            </button>
          </div> */}
        </div>
      )}
      {isOpenTemplatePopup && (
        <TemplateSaveModal
          show={isOpenTemplatePopup}
          handleClose={handleCloseTemplatePopup}
          templateName={templateName}
          setTemplateName={setTemplateName}
          handleSubmit={handleTemplateSubmit}
          isSaving={isSaving}
        />
      )}
    </div>
  );
};

export default Cart;
