import logo from "../assets/kedi logo.png";
import { Link, Outlet, useLocation } from "react-router-dom";
import Banners from "./Banners";
import Button from "react-bootstrap/Button";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import ShoppingBasketOutlinedIcon from "@mui/icons-material/ShoppingBasketOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";

import "./Navbar.css";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function Navbar({ setIsAuthenticated, isVendorName, isAdmin }) {
  const location = useLocation();
  const { cartList } = useSelector((state) => state.cart);

  const totalCartItems = cartList.length;

  // Logout
  function logout() {
    localStorage.removeItem("kedTokAuth");
    sessionStorage.removeItem("kedTokAuth");
    setIsAuthenticated(false);
  }

  return (
    <>
      {isAdmin ? (
        <nav className="py-5 bg-gray-200 header-navbar">
          <div className=" header-slider flex items-center justify-center mx-auto gap-x-4">
            <div className="">
              <Link to="/app">
                <img src={logo} alt="" />
              </Link>
            </div>
            <ul className="flex gap-x-6 flex-wrap items-center justify-center">
              <li className="nav-link-txt">
                <Link to="/app">Αρχική</Link>
              </li>
              <li className="nav-link-txt">
                <Link to="/app/reports">Report</Link>
              </li>
            </ul>
            <li className="nav-link-txt">
              <Button
                variant="danger border-0"
                style={{
                  color: "red",
                  bottom: "5px",
                  position: "relative",
                }}
                onClick={logout}
              >
                Aποσύνδεση
              </Button>
            </li>
          </div>
        </nav>
      ) : (
        <nav className="py-5 bg-gray-200 header-navbar">
          <div className=" header-slider flex items-center justify-center mx-auto gap-x-11">
            <Link to="/app">
              <img src={logo} alt="" />
            </Link>
            <ul className="flex gap-x-6 flex-wrap items-center justify-center">
              <li className="nav-link-txt">
                <Link to="/app">Αρχική</Link>
              </li>
              <li className="nav-link-txt">
                <Link to="/app/catalogue">Κατάλογος</Link>
              </li>
              <li className="nav-link-txt">
                <Link to="/app/orders">Παραγγελίες</Link>
              </li>
              {isVendorName == "" && (
                <>
                  <li className="nav-link-txt">
                    <Link to="/app/invoices">Τιμολόγια</Link>
                  </li>
                  <li className="nav-link-txt">
                    <Link to="/app/customer-statements">
                      Κατάσταση λογαριασμού
                    </Link>
                  </li>
                  <li className="nav-link-txt">
                    <Link to="/app/vendors">Αντιπρόσωποι</Link>
                  </li>
                  <li className="nav-link-txt">
                    <Link to="/app/return-policy">Πολιτική επιστροφών</Link>
                  </li>
                  <li className="nav-link-txt">
                    <a href="http://kedifap.com2go.co/portal" target="_blank">
                      Claims
                    </a>
                  </li>
                  {/* <li className="nav-link-txt">
                    <Link to="/app/profile">Το προφίλ μου</Link>
                  </li> */}
                </>
              )}
              {isVendorName !== "" && (
                <li className="nav-link-txt">
                  <Link to="/app/reports">Reports</Link>
                </li>
              )}
              <li className="nav-link-txt">
                <Link to="/app/backorders">Backorders</Link>
              </li>

              <li className="nav-link-txt">
                <Link to="/app/wishlist">Πρότυπα Παραγγελιών</Link>
              </li>
              {/* <li className="nav-link-txt">
                <Link to="/app/return-product">Return product</Link>
              </li> */}
              {/* {isVendorName !== "" && (
                <li className="nav-link-txt">
                  <Link to="/app/contact">Επικοινωνία</Link>
                </li>
              )} */}
            </ul>
            <li className="nav-link-txt">
              <Button
                variant="danger border-0"
                style={{
                  color: "red",
                  bottom: "5px",
                  position: "relative",
                }}
                onClick={logout}
              >
                Aποσύνδεση
              </Button>
            </li>
          </div>
        </nav>
      )}
      <div className="w-full md:w-4/5 md:mx-auto px-0 md:px-4 banners">
        {isVendorName == "" && <Banners />}
        <div className="max-h-screen md:h-full flex flex-col">
          <main className="pb-14">
            {location?.pathname !== "/app" && (
              <div className="lg:hidden px-2 py-1 flex justify-between">
                <img src={logo} width={100} height="auto" />
                {isVendorName !== "" && (
                  <div className="lg:hidden">
                    <Link
                      to="/app/reports"
                      className="text-blue-600 flex gap-1"
                    >
                      <img src="/report-icon.jpeg" width={20} />
                      <span>Reports</span>
                    </Link>
                  </div>
                )}
              </div>
            )}
            <Outlet />
          </main>
          <footer className="lg:hidden mt-auto flex gap-1 justify-between p-2 bg-white border-t border-slate-400 fixed bottom-0 left-0 w-screen z-20">
            <Link
              className="flex flex-col justify-center items-center"
              to="/app"
            >
              <HomeOutlinedIcon
                color={location?.pathname === "/app" ? "success" : "black"}
              />
              <p className="text-md font-medium text-center text-black">Home</p>
            </Link>
            <Link
              className="flex flex-col justify-center items-center"
              to="/app/catalogue"
            >
              <DescriptionOutlinedIcon
                color={
                  location?.pathname === "/app/catalogue" ? "success" : "black"
                }
              />
              <p className="text-md font-medium text-center text-black">
                Catalogue
              </p>
            </Link>
            <Link
              className="flex flex-col justify-center items-center"
              to="/app/orders"
            >
              <ListAltOutlinedIcon
                color={
                  location?.pathname === "/app/orders" ? "success" : "black"
                }
              />
              <p className="text-md font-medium text-center text-black">
                Orders
              </p>
            </Link>
            <Link
              className="flex flex-col justify-center items-center relative"
              to="/app/catalogue?cart=true"
            >
              <ShoppingBasketOutlinedIcon color="black" />
              <p className="text-md font-medium text-center text-black">Cart</p>
              <span className="absolute -top-2 -right-6 bg-red-700 text-white font-medium h-8 w-8 flex justify-center items-center rounded-full">
                {totalCartItems}
              </span>
            </Link>
            <div
              className="flex flex-col justify-center items-center"
              onClick={logout}
            >
              <LogoutOutlinedIcon color="black" />
              <p className="text-md font-medium text-center text-black">
                Logout
              </p>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
}
