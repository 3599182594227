import axios from "axios";
import moment from "moment";

export const fetchSavedTemplate = async (
  sorting,
  columnFilters,
  setData,
  setRowCount,
) => {

  const userId = localStorage.getItem("userId");
  const url = new URL(`${process.env.REACT_APP_API_URL}/save-template?userId=${userId}`);
  url.searchParams.set("filters", JSON.stringify(columnFilters ?? [])); //[{"id":"PARTNAME","value":"sa"}]
  url.searchParams.set("sorting", JSON.stringify(sorting ?? []));

  try {
    const response = await axios.get(url);

    const templatesDataRes = response.data.map((item) => ({
      ...item,
      DEXT_SUBMISSIONDATE: moment(item.created_at).format(
        "DD-MM-YYYY"
      ),
    }));

    setData(templatesDataRes);
    setRowCount(templatesDataRes.length);
  } catch (error) {
    console.error(error);
  }
};

export const fetchSavedTemplate1 = async (userId) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}/save-template?userId=${userId}`);

  try {
    const response = await axios.get(url);
    console.log(response.data, "saved template");

    return response.data;
  } catch (error) {
    console.error(error, "saved template get error");
    throw error;
  }
};

export const postSavedTemplate = async (body) => {
  const url = `${process.env.REACT_APP_API_URL}/save-template`;

  try {
    const response = await axios.post(url, body);

    console.log(response.data, "saved template created");

    return response.data;
  } catch (error) {
    console.error(error, "saved template post error");
    throw error;
  }
};


export const updateSavedTemplate = async (body, id) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}/save-template/${id}`);

  try {
    const response = await axios.post(url, body);

    console.log(response.data, "saved template updated");

    return response.data;
  } catch (error) {
    console.error(error, "saved template post error");
    throw error;
  }
};

export const deleteSavedTemplate = async (id) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}/save-template/${id}`);

  try {
    const response = await axios.delete(url);

    console.log(response.data, "saved template deleted");

    return response.data;
  } catch (error) {
    console.error(error, "saved template post error");
    throw error;
  }
};
