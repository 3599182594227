import React, { useEffect, useState } from "react";

import { GoPrimitiveSquare } from "react-icons/go";
import logo from "../assets/kedi logo.png";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/scrollbar";
import { Link } from "react-router-dom";

function Home({ isVendorName }) {
  const [welcomeUser, setWelcomeUser] = useState("");
  const [customerDetails, setCustomerDetails] = useState(undefined);
  const [announcements, setAnnouncements] = useState([]);
  const [advertisement, setAdvertisement] = useState([]);

  let userDesc = localStorage.getItem("userDesc");
  let userId = localStorage.getItem("userId");

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setWelcomeUser(userDesc);
  }, [welcomeUser]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://app.portal.kedifap.com/public/announcements.json"
        );
        const data = await response.json();
        setAnnouncements(data);
      } catch (error) {
        console.error("Error fetching announcements:", error);
      }
    };

    fetchData();
  }, []);

  const isCurrentDateInRange = (data) => {
    const currentDate = new Date();
    const fromDate = new Date(data.from.split("/").reverse().join("/"));
    const toDate = new Date(data.to.split("/").reverse().join("/"));
    console.log(
      currentDate >= fromDate && currentDate <= toDate,
      "currentDate"
    );

    return currentDate >= fromDate && currentDate <= toDate;
  };

  const fetchAdvertisement = async () => {
    try {
      const response = await fetch(
        "https://app.portal.kedifap.com/public/advertisement.json"
      );
      const data = await response.json();
      console.log(data, "adsdata");
      if (data && data?.length > 0) {
        const filteredData = data.filter(
          (item) =>
            isCurrentDateInRange(item) &&
            item?.towns?.includes(customerDetails?.STATEA) &&
            item?.pharma?.includes(customerDetails?.CUSTNAME)
        );
        setAdvertisement(filteredData);
        if (filteredData.length > 0) {
          handleOpen();
        }
      }
    } catch (error) {
      console.error("Error fetching advertisement:", error);
    }
  };

  useEffect(() => {
    if (customerDetails) {
      fetchAdvertisement();
    }
  }, [customerDetails]);

  const getCustomerDetails = () => {
    const [user, dCode] = userId.split("-");
    axios
      .get(`https://portal.kedifap.com/customer-details?CUSTNAME=${user}`)
      .then((phoneBook) => setCustomerDetails(phoneBook?.data[0]));
  };
  useEffect(() => {
    getCustomerDetails();
  }, [userId]);

  const renderedAnnoucements = announcements.map((announce, index) => (
    <div className="flex items-center py-3" key={index}>
      <GoPrimitiveSquare className="bg-kedifapgreen-300 text-kedifapgreen-300 mr-4 shadow-top rounded" />
      {announce?.url ? (
        <a href={announce?.url} target="_blank">
          {announce.title}
        </a>
      ) : (
        announce.title
      )}
    </div>
  ));

  return (
    <div className="px-2">
      <div className="lg:hidden mb-2 mt-3 flex justify-between">
        <img src={logo} width={120} height={50} />
        {isVendorName !== "" && (
          <div className="lg:hidden">
            <Link to="/app/reports" className="text-blue-600 flex gap-1">
              <img src="/report-icon.jpeg" width={20} />
              <span>Reports</span>
            </Link>
          </div>
        )}
      </div>
      <div>
        <h1 className="text-xl md:text-2xl pt-4 pb-0 md:py-4">
          Welcome {welcomeUser !== "undefined" ? welcomeUser : userId}
        </h1>
      </div>
      {isVendorName == "" && (
        <div>
          <h2 className="text-xl md:text-2xl py-2 md:py-4">Ανακοινώσεις</h2>
          <div>{renderedAnnoucements}</div>
          {advertisement && advertisement.length > 0 && (
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="advertisement-dialog-title"
              aria-describedby="advertisement-dialog-description"
              className="relative rounded-0"
            >
              <button
                className="absolute top-1 right-1 bg-white text-black w-5 h-5 text-center rounded-full font-bold leading-3"
                style={{ zIndex: 2 }}
                onClick={handleClose}
              >
                <CloseIcon fontSize="18px" />
              </button>
              <Swiper
                modules={[Scrollbar, A11y, Autoplay]}
                autoplay={true}
                style={{
                  height: "250px",
                  width: "300px",
                  margin: 0,
                  padding: 0,
                }}
                scrollbar={{ draggable: true }}
              >
                {advertisement.map((item) => (
                  <SwiperSlide>
                    <img
                      className="w-full"
                      src={`https://app.portal.kedifap.com${item?.adsImg}`}
                      alt={item?.name}
                      style={{
                        objectFit: "fill",
                        width: "300px",
                        height: "250px",
                      }}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Dialog>
          )}
        </div>
      )}
    </div>
  );
}

export default Home;
