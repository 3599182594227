import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

// Register the different font weights
Font.register({
  family: "Roboto",
  src: "/fonts/Roboto-Regular.ttf",
  fontWeight: "normal",
});
Font.register({
  family: "Roboto",
  src: "/fonts/Roboto-Medium.ttf",
  fontWeight: "bold",
});

// Define styles
const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
    fontFamily: "Roboto",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  headerText: {
    fontSize: 12,
    fontWeight: "bold",
  },
  headerTextLarge: {
    fontSize: 16,
    fontWeight: "bold",
  },
  sectionTitle: {
    marginVertical: 8,
    fontSize: 10,
    fontWeight: "bold",
  },
  table: {
    width: "100%",
    marginVertical: 10,
    borderCollapse: "collapse",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    padding: 4,
    border: "1px solid #000",
    width: "14.2857%", // for 7 columns
    textAlign: "center",
  },
  tableHeader: {
    backgroundColor: "#eeeeee",
    fontWeight: "bold",
  },
  signatureBlock: {
    marginTop: 30,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  signatureLine: {
    width: "40%",
    borderTop: "1px solid #000",
    textAlign: "center",
    marginTop: 10,
  },
});

// Create Document Component
export const ReturnProductPdf = ({ allReturnProducts, otherInfo }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Header Section */}
        <View style={styles.header}>
          <Text style={styles.headerTextLarge}>ΔΕΛΤΙΟ ΕΠΙΣΤΡΟΦΗΣ</Text>
        </View>
        {/* Customer Info Section */}
        <View style={styles.header}>
          <Text style={styles.headerText}>
            Customer: {otherInfo.customer.userFullId}
          </Text>
          <Text style={styles.headerText}>
            Box No.: {otherInfo.boxNumber || "N/A"}
          </Text>
        </View>
        <View style={styles.header}>
          <Text style={styles.headerText}>
            Serial No.: {otherInfo.serialNo}
          </Text>
          <Text style={styles.headerText}>
            Date: {new Date().toLocaleDateString()}
          </Text>
        </View>
        <View style={styles.header}>
          <Text style={styles.headerText}>
            Customer Name: {otherInfo.customer.name}
          </Text>
        </View>

        {/* Table Section */}
        <Text style={styles.sectionTitle}>Product Return Details</Text>
        <View style={styles.table}>
          {/* Table Header */}
          <View style={[styles.tableRow, styles.tableHeader]}>
            <Text style={styles.tableCell}>CODE</Text>
            <Text style={styles.tableCell}>Description</Text>
            <Text style={styles.tableCell}>Return Policy</Text>
            <Text style={styles.tableCell}>Exp. Date</Text>
            <Text style={styles.tableCell}>Lot No.</Text>
            <Text style={styles.tableCell}>QTY</Text>
            <Text style={styles.tableCell}>Special Agreement</Text>
          </View>
          {/* Table Body */}
          {allReturnProducts.map((product, index) => (
            <View style={styles.tableRow} key={index}>
              <Text style={styles.tableCell}>{product.PARTNAME}</Text>
              <Text style={styles.tableCell}>{product.PARTDES}</Text>
              <Text style={styles.tableCell}>{product.DEXT_CSPOLICYCODE}</Text>
              <Text style={styles.tableCell}>{product.expiry}</Text>
              <Text style={styles.tableCell}>{product.lotNo}</Text>
              <Text style={styles.tableCell}>{product.qty}</Text>
              <Text style={styles.tableCell}>
                {product.agreement ? "Yes" : "No"}
              </Text>
            </View>
          ))}
        </View>

        {/* Signature Section */}
        <View style={styles.signatureBlock}>
          <View style={styles.signatureLine}>
            <Text>Όνομα διανομέα :</Text>
          </View>
          <View style={styles.signatureLine}>
            <Text>Όνομα Φαρμακοποιού :</Text>
          </View>
        </View>
        <View style={styles.signatureBlock}>
          <View style={styles.signatureLine}>
            <Text>Υπ. Διανομέα :</Text>
          </View>
          <View style={styles.signatureLine}>
            <Text>Υπ. Φαρμακοποιού :</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
