import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { FaCartArrowDown } from "react-icons/fa";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import "../CartPopupModal.css";
import { setCartDetails } from "../../../store/reducer/cart";
import toast from "react-hot-toast";

function SingleCartPopupModal(props) {
  const {
    cartList,
    dispatch,
    showSingleCartPopup,
    setShowSingleCartPopup,
    row,
  } = props;

  const [currentQuantity, setCurrentQuantity] = useState(1);

  const addSingleCart = () => {
    // if (cartList && cartList?.length === 40) {
    //   toast.error(
    //     "You have reached the limit of ordered items. Please send order and continue with new one."
    //   );
    //   return;
    // }

    // IF PRODUCT ALREADY IN CART HANDLE ERROR AND RETURN
    const found = cartList.find(
      (element) => element.PARTNAME === row?.PARTNAME
    );
    if (found) {
      toast.error("Product is already in the cart!");
      return;
    }

    row.quantity = Number(currentQuantity || 1);
    dispatch(setCartDetails({ cartList: [...cartList, row] }));
    toast.success("Product added in the cart!");
  };

  return (
    <Modal
      show={showSingleCartPopup}
      onHide={() => {
        setShowSingleCartPopup(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Confirm Add to Cart</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <div class="px-padding">
            <li class="cart-item colored-bg">
              <Col>
                <div class="cart-item-details">
                  <p class="cart-item-name">
                    <b>{row?.PARTDES}</b>
                  </p>
                  <p class="cart-item-name">Code: {row?.PARTNAME}</p>
                  <p class="cart-item-price">
                    Price: {parseFloat(row?.WSPLPRICE)}
                  </p>
                </div>
              </Col>
              <Col>
                <div class="cart-item-controls px-padding right-float gap-2">
                  <div className="flex gap-1">
                    <button
                      style={{
                        width: "38px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                        padding: "2px",
                        fontSize: "1.2rem",
                        textAlign: "center",
                        fontWeight: "bold",
                        backgroundColor: "#f2f2f2",
                      }}
                      onClick={() => {
                        if (currentQuantity === 1) return;
                        setCurrentQuantity((prev) => prev - 1);
                      }}
                    >
                      -
                    </button>
                    <input
                      type="number"
                      value={currentQuantity}
                      onChange={(e) => {
                        setCurrentQuantity(e.target.value);
                      }}
                      style={{
                        width: "50px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                        padding: "5px",
                        fontSize: "1rem",
                        textAlign: "center",
                      }}
                    />
                    <button
                      style={{
                        width: "38px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                        padding: "3px",
                        fontSize: "1.1rem",
                        textAlign: "center",
                        fontWeight: "bold",
                        backgroundColor: "#f2f2f2",
                      }}
                      onClick={() => {
                        setCurrentQuantity((prev) => prev + 1);
                      }}
                    >
                      +
                    </button>
                  </div>
                  <button
                    className="n bg-kedifapgreen-300 hover:bg-kedifapred-700 text-white p-3 rounded-3xl shadow-lg"
                    onClick={addSingleCart}
                    style={{
                      width: "30px",
                      fontSize: "15px",
                      height: "30px",
                    }}
                  >
                    <FaCartArrowDown
                      style={{
                        right: "8px",
                        bottom: "8px",
                        position: "relative",
                      }}
                    />
                  </button>
                </div>
              </Col>
            </li>
          </div>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          style={{ color: "red" }}
          onClick={() => {
            setShowSingleCartPopup(false);
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SingleCartPopupModal;
